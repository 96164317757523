.min-width {
    width: 6.25em;
}

.right-align {
    float: right;
    margin-left: 1em;
}

.coupon-description {
    min-height: 5em;
    max-height: 9.375em;
}

.cancel-coupon {
    background-color: white;
    color: #3e4676;
}

.cancel-coupon:hover {
    background-color: #00875e;
    color: white;
}

.coupon-margin-bottom {
    margin-bottom: 1em;
}

.coupon-filter-margin {
    margin-bottom: 1em;
}

.warning-label {
    display: block;
    text-align: right;
    color: #ab000d;
    font-size: 0.8em;
    margin-top: -10px;
    white-space: nowrap;
}

.warning-text {
    margin-left: 0.5em;
    color: grey;
}

.success-check {
    position: absolute;
    color: #00875e;
    top: 20%;
    left: 93%;
    animation: 1s fadeIn;
}

.failed-check {
    position: absolute;
    color: #ab000d;
    top: 20%;
    left: 93%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

.edit-coupon {
    display: flex;
    justify-content: space-around;
}

.edit-button {
    margin: 0 0.5em;
}