.date-filter-component {
  /* margin-top: 1em; */
}

.go-buttons {
  margin: 3em 0.5em 0em 0em;
  width: 100px;
  height: 2.1em;
  background-color: #00c789 !important;
  border: none;
}

.reset-buttons {
  margin: 3em 0.5em 0em 0.5em;
  width: 100px;
  height: 2.1em;
  background-color: #6b757c !important;
  border: none;
}

.medication-btns{
  width: 68px;
  margin-top: 25px !important;
}

.export-button {
  margin-top: -0.5em;
}

.appointment-list {
  margin-top: -2em;
  padding-bottom: 4em;
}

.appointment-list-view-btn {
  background-color: white;
  border: 2px solid #00875e;
  color: #3f4674;
}

.appointment-table-expanded {
  background-color: #e0f8ee;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 0.25em 0.25em 0em 0.7em;
  margin: 0.25em;
}

.appointment-table-expantion-area {
  width: 98%;
  margin: auto;
}

.search-box {
  position: absolute;
  width: inherit;
  top: 0em;
  right: 5em;
}

.clear-search-box {
  position: absolute;
  right: 0.2em;
  top: 0em;
  background-color: #e0f8ee;
  border: 1px solid #e0f8ee;
}

.separator {
  height: 2px;
  background-color: #e0f8ee;
  margin: 0.5em auto;
}

.eye-button {
  border-color: #00875e;
  color: #00875e;
  margin-left: 5px;
}

.eye-button:hover {
  border-color: white;
  background-color: #00875e;
  color: white;
}

.money-button {
  border-color: #3f86fa;
  color: #3f86fa;
  margin-left: 0.3em;
}

.money-button:hover {
  border-color: white;
  background-color: #3f86fa;
  color: white;
}

.delete-btn {
  border-color: #f34134;
  color: #f34134;
  margin-left: 0.3em;
}

.delete-btn:hover {
  border-color: white;
  background-color: #f34134;
  color: white;
}

.second-row-filters {
  padding-top: 1em;
}

.number-of-records {
  margin: 0.6em 0 -0.7em 0;
}

.blank-state {
  text-align: center;
  margin-top: 1em;
}

/* .appointment-list tbody {
    display: block;
    height: 31vh;
    overflow-y: scroll;
  }
  
  .appointment-list thead {
    display: table;
    width: 98.7%;
    table-layout: fixed;
  }
  
  .appointment-list tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  } */
.patient-list {
  margin-top: -2em;
}

.patient-list tbody {
  display: block;
  max-height: 32.5vh;
  overflow-y: scroll;
  min-height: auto;
}

.patient-list thead {
  display: table;
  width: 98.7%;
  table-layout: fixed;
}

.patient-list tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.button-width {
  height: 2.1em;
  min-width: 70px;
  max-width: auto;
  font-size: 1em;
  padding: 0.5em;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

.yes-button {
  background-color: #38ad6f;
  border: 1px solid #38ad6f;
  padding: 0 0.5em;
}

.yes-button:disabled {
  background-color: #4dc585;
  border: 1px solid #4dc585;
  padding: 0 0.5em;
}

.yes-button:hover {
  background-color: #0a4f21;
  border: 1px solid #0a4f21;
  padding: 0;
}

.next-button {
  background-color: #009bc8;
  border: 1px solid #009bc8;
  padding: 0;
}

.next-button:hover {
  background-color: #026988;
  border: 1px solid #026988;
  padding: 0;
}

.no-button {
  color: #203a45;
  border: 1px solid #4dc585;
  padding: 0;
}

.close-btn-alone {
  color: white;
  background-color: #1bc269;
  border: 1px solid #20c56d;
  padding: 0;
}

.close-btn-alone:hover {
  color: rgb(79, 72, 72);
  background-color: #4dc585;
  border: 1px solid #4dc585;
  padding: 0;
}

.no-button:hover {
  background-color: #0a4f21;
  border: 1px solid #0a4f21;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.patient_table {
  margin-top: -22px !important;
}

.view-appt-btn {
  background-color: #00875e;
  border: none;
  color: white;
  padding: 0.30em;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

.view-appt-btn:hover {
  background-color: #00be85;
  border: none;
  text-decoration: none;
  color: white;
}

.cancel-appt-button-width {
  height: 2em;
  width: 100px;
  font-size: 1em;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

.required {
  color: red;
}

.datepicker-wrapper .react-datepicker__input-container input,
.search-wrapper .input-wrapper .react-datepicker__input-container:focus input,
.search-wrapper .input-wrapper .form-control,
.search-wrapper .input-wrapper .form-control:focus,
.search-wrapper .react-select-container .react-select__control {
  border-color: transparent !important;
  background-color: transparent !important;
}

.datepicker-wrapper,
.input-wrapper,
.select-wrapper {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  border-right: 0;
  padding: 0.125rem 0.125rem 0.125rem 0.5rem;
}

.input-wrapper {
  border-left: 1px solid #ced4da;
}

.datepicker-wrapper {
  border-right: 1px solid #ced4da;
}

.react-datepicker__input-container input {
  outline: none !important;
  color: #3e4676;
}

.action-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reason-for-cancellation {
  min-height: 5em;
  max-height: 5em;
}

.todays-appt-table {
  padding: 0 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.todays-appt-card {
  height: 70vh;
  margin-top: 2em;
}

.todays-appt-card-header {
  font-size: 1.3em;
  color: #000 !important;
}

.todays-appt-table tbody {
  display: block;
  height: 52vh;
  overflow-y: scroll;
}

.todays-appt-table tbody::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
  /* Chrome/Safari/Webkit */
}

.todays-appt-table tbody {
  scrollbar-width: none !important;
  /* Firefox */
  -ms-overflow-style: none !important;
  /* IE 10+ */
}

.todays-appt-table thead {
  display: table;
  width: 98.7%;
  table-layout: fixed;
}

.todays-appt-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.appo-list-table {
  margin-top: -3em;
}

.pharmacy-address-color {
  color: grey;
}

.overflow-modal {
  min-height: 30vh;
  max-height: 55vh;
  overflow: auto;
}

.pharmacy-address-textarea {
  max-height: 117px;
  min-height: 117px;
}

.Filter-address {
  min-width: 33.4%;
}

.email-button {
  margin-left: 5px;
  border-color: #00875e;
  color: #00875e;
}

.email-button:hover {
  border-color: white;
  background-color: #00875e;
  color: white;
}

.pharmacy-badge {
  font-size: 1em;
  min-width: 80px;
  max-width: auto;
}

.button-corporate {
  margin-top: 8.6%;
}

.phone-number-input {
  display: block;
  width: 100%;
  height: calc(1.90625rem + 2px);
  padding: 0.25rem 0.7rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn-margin {
  margin-right: 10px;
}

.no-data-found-card {
  padding: 2em;
  margin: auto;
}

.advanced-search-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.advanced-search-radio-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.2em;
}

.adv-search-background-card {
  background-color: #f5f5f5;
  padding: 1em;
}

@-webkit-keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.advanced-search-table {
  -webkit-animation: slideIn 1.5s ease;
  animation: slideIn 1.5s ease;
}

.advanced-search-table .table tr td:nth-child(1), .advanced-search-table .table tr th:nth-child(1) {
  text-align: center;
  width: 68px;
}

.advanced-search-table :nth-child(2) {
  width: 150px;
}

.advanced-search-table :nth-child(4) {
  width: 200px;
}

@media screen and (max-height: 700px) {
  .adv-search-modal-body {
    height: 450px;
  }
  .advanced-search-table-height {
    height: 250px;
    overflow: scroll;
    word-break: break-word;
  }
}

.medication-go-reset-btn{
  margin-top: 3%;
  
}

.radio-cntrl{
  margin-left: 3px;
}
.radio-cntrl-label{
  margin-left: 18px;
}
.radio-un-ctrl{
  margin-left: 12px;
}
.radio-un-ctrl-label{
  margin-left: 28px;
}

@media screen and (min-height: 900px) {
  .adv-search-modal-body {
    height: 600px;
  }
  .advanced-search-table-height {
    height: 400px;
    overflow: scroll;
    word-break: break-word;
  }
}
@media screen and (min-height: 700px) and (max-height: 900px) {
  .adv-search-modal-body {
    height: 550px;
  }
  .advanced-search-table-height {
    height: 350px;
    overflow: scroll;
    word-break: break-word;
  }
}

.action-btn-filter{
  margin-top: -2px;
}

.toggle-button {
  margin-top: .5em;
}


.add-refund-btn{
  margin-top: -3px;
  margin-bottom: 7px;
  background-color: #38ad6f;
  border: 1px solid #38ad6f;

}

.add-refund-btn:hover{
  background-color: #00905D;
  border-color: #00905D;
  
}

.min-min-hr-font {
  font-size: 13px;
}

.centralize{
  display: flex;
  align-items: center;
  justify-content: center;
}
.rule-legend {
  background-color: #F7D159;
  height: 1em;
  width: 1em;
  margin-bottom: 3px;

}
.rule-legend-text {
  margin-left: 1.5em;
  margin-top: -21px;
  display: flex;

}

.container{
  padding: 5px;
}

.cancel-charge{
  padding-top: 5%;
  padding-bottom: 5%;
}