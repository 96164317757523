.row-break {
    margin-bottom: 0.75rem;
  }
  .config-btn-wrapper {
    margin-top: 1rem;
  }
  
  .checkbox-arr {
    display: flex;
    justify-content: space-between;
  }
  
  .schedule-widget {
    border-radius: 4px;
    min-height: 100px;
    border: 1px solid #f0f0f0;
    margin: 0.9em;
  }
  
  .schedule-header h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 3px 0 0;
  }
  
  .schedule-header {
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;
  }
  
  .day-slot ul {
    float: left;
    list-style: none;
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
    position: relative;
    width: 100%;
  }
  
  .day-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 14.28%;
  }
  
  .day-slot li span {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .day-slot li span.slot-date {
    display: block;
    color: #757575;
    font-size: 14px;
  }
  
  .day-slot li small.slot-year {
    color: #757575;
    font-size: 14px;
  }
  
  .day-slot li.left-arrow {
    left: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
  }
  
  .day-slot li.right-arrow {
    right: -11px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
  }
  
  .schedule-cont {
    padding: 20px;
  }
  
  .time-slot ul {
    list-style: none;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 0;
    padding: 0;
  }
  
  .time-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    width: 14.28%;
  }
  
  .time-slot li .timing {
    background-color: #e9e9e9;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    color: #757575;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px 5px;
    text-align: center;
    position: relative;
  }
  
  .time-slot li .timing:hover {
    background-color: #fff;
  }
  
  .time-slot li .timing:last-child {
    margin-bottom: 0;
  }
  
  .time-slot li .timing.selected {
      background: #38C485;
    border: 1px solid #38C485 !important;
    color: #fff;
  }
  
  .time-slot li .timing.selected::before {
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-size: 12px;
    font-weight: 900;
    position: absolute;
    right: 6px;
    top: 6px;
  }
  
  .schedule-list {
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  
  .schedule-list:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .submit-section.proceed-btn {
  margin: 0 0 30px;
  }
  
  .tab-content.schedule-cont .card-title {
      margin-bottom: 10px;
  }
  
  .doc-times {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .doc-times.doc-times-modal {
    padding: 0 32px;
  }


  .doc-times.doc-times-modal .delete_schedule {
    cursor: pointer;
  }

  
  .schedule-nav .nav-tabs {
      border: 0 !important;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  
  .schedule-nav .nav-tabs li {
      margin: 5px 15px 5px 0; 
      display: inline-block;
  }
  
  .schedule-nav .nav-tabs li:hover {
    background-color: #f1f1f1;
    border-radius: 3px;
    transition: 100ms ease;
  }

  .schedule-nav .nav-tabs li:active {
    background-color: #8fe7cc;
    border-radius: 3px;
  }
  
  .schedule-nav .nav-tabs li:last-child {
      margin-right: 0;
  }
  
  .schedule-nav .nav-tabs > li > a {
      border: 1px solid #dcddea;
      border-radius: 4px;
      padding: 6px 15px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .schedule-nav .nav-tabs li a.active {
      background: #38C485;
    border: 1px solid #38C485 !important;
      color: #fff;
  }
  
  .hours-info .form-control {
      min-height: auto;
  }
  
  .hours-info .btn.btn-danger.trash {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    height: 38px;
    width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
  }
  
  .edit-link {
    color: #203A45;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .add-hours {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #38C485!important;
  }
  .add-hours > svg {
    margin-right: 4px;
  }
  
  .add-row-btn {
    height: 32px;
  }

  .add-row-btn .btn-primary {
    background: #38C485!important;
    border-color: #38C485!important;
  }

  .add-row-btn.btn-primary {
    background: #38C485!important;
    border-color: #38C485!important;
  }

  .delete-row-btn.btn-primary {
    background: #c43838!important;
    border-color: #c43838!important;
  }

  .availability-calender {
    margin-top: -2em !important;
    width: 97%;
    margin: auto;
  }

  .availability-card {
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: solid 1em white;
  }

  .availability-card-adjustment {
    height: 2.2em;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
  }

  .fc-scroller::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* Chrome/Safari/Webkit */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
  }

  .time-zone-card {
    height: 2.5em;
    margin: auto;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0.5em; 
    border-radius: 20px;
    background-color: #e8e8e8;
  }

  .time-zone-exclamation {
    margin: 1em 0.5em 1em 0;
    margin-bottom: 0.5em;
    color: #00a1fa;
  }

  @media screen and (max-width: 768px) {
    .checkbox-arr {
      flex-direction: column;
    }
    .doc-times.doc-times-modal {
      padding: 0 16px;
    }
  }
  
  .row-break {
    margin-bottom: 0.75rem;
  }
  .config-btn-wrapper {
    margin-top: 1rem;
  }
  
  .checkbox-arr {
    display: flex;
    justify-content: space-between;
  }
  
  .schedule-widget {
    border-radius: 4px;
    min-height: 100px;
    border: 1px solid #f0f0f0;
    margin-top: 1em;
  }
  
  .schedule-header h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 3px 0 0;
  }
  
  .schedule-header {
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;
  }

  .speciality-dropdown-spec{
    /* z-index: 9; */
    width: 23%;
    position: relative;
    margin-bottom: -2.8%;
    float: right;

}

@media screen and (min-width: 768px) {
  .speciality-dropdown-spec{
    /* z-index: 9; */
    width: 22%;
    position: relative;
    margin-bottom: -2.3em;
    padding-right: 0.8em;
    margin-left: 68%;
  }
}
  
  .day-slot ul {
    float: left;
    list-style: none;
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
    position: relative;
    width: 100%;
  }
  
  .day-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 14.28%;
  }
  
  .day-slot li span {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .day-slot li span.slot-date {
    display: block;
    color: #757575;
    font-size: 14px;
  }
  
  .day-slot li small.slot-year {
    color: #757575;
    font-size: 14px;
  }
  
  .day-slot li.left-arrow {
    left: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
  }
  
  .day-slot li.right-arrow {
    right: -11px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
  }
  
  .schedule-cont {
    padding: 20px;
  }
  
  .time-slot ul {
    list-style: none;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 0;
    padding: 0;
  }
  
  .time-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    width: 14.28%;
  }
  
  .time-slot li .timing {
    background-color: #e9e9e9;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    color: #757575;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px 5px;
    text-align: center;
    position: relative;
  }
  
  .time-slot li .timing:hover {
    background-color: #fff;
  }
  
  .time-slot li .timing:last-child {
    margin-bottom: 0;
  }
  
  .time-slot li .timing.selected {
      background: #38C485;
    border: 1px solid #38C485 !important;
    color: #fff;
  }
  
  .time-slot li .timing.selected::before {
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-size: 12px;
    font-weight: 900;
    position: absolute;
    right: 6px;
    top: 6px;
  }
  
  .schedule-list {
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  
  .schedule-list:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .submit-section.proceed-btn {
  margin: 0 0 30px;
  }
  
  .tab-content.schedule-cont .card-title {
      margin-bottom: 10px;
  }
  
  .doc-times {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .doc-slot-list {
    background-color: #1b905b;
    border: 1px solid #1b905b;
    border-radius: 4px;
    color: #fff;
    font-size: 13px;
    margin: 5px 5px 0 0;
  }
  
  .doc-slot-list a {
      color: #e48784;
      margin-right: 10px;
  }
  
  .doc-slot-list a:hover {
      color: #fff;
  }

  .doc-times.doc-times-modal {
    padding: 0 32px;
  }

  .doc-slot-list-modal {
    padding: 1px 4px;
  }

  .doc-times.doc-times-modal .delete_schedule {
    cursor: pointer;
  }

  
  .schedule-nav .nav-tabs {
      border: 0 !important;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  
  .schedule-nav .nav-tabs li {
      margin: 5px 15px 5px 0; 
      display: inline-block;
  }
  
  .schedule-nav .nav-tabs li:hover {
    background-color: #f1f1f1;
    border-radius: 3px;
    transition: 100ms ease;
  }

  .schedule-nav .nav-tabs li:active {
    background-color: #8fe7cc;
    border-radius: 3px;
  }
  
  .schedule-nav .nav-tabs li:last-child {
      margin-right: 0;
  }
  
  .schedule-nav .nav-tabs > li > a {
      border: 1px solid #dcddea;
      border-radius: 4px;
      padding: 6px 15px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .schedule-nav .nav-tabs li a.active {
      background: #38C485;
    border: 1px solid #38C485 !important;
      color: #fff;
  }
  
  .hours-info .form-control {
      min-height: auto;
  }
  
  .hours-info .btn.btn-danger.trash {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    height: 38px;
    width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
  }
  
  .edit-link {
    color: #203A45;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .add-hours {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #38C485!important;
  }
  .add-hours > svg {
    margin-right: 4px;
  }
  
  .add-row-btn {
    height: 32px;
  }

  .add-row-btn .btn-primary {
    background: #38C485!important;
    border-color: #38C485!important;
  }

  .add-row-btn.btn-primary {
    background: #38C485!important;
    border-color: #38C485!important;
  }

  .delete-row-btn.btn-primary {
    background: #c43838!important;
    border-color: #c43838!important;
  }

  .availability-calender {
    width: 97%;
    margin: auto;
  }

  .availability-card {
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: solid 1em white;
  }

  @media (max-width: 768px) {
    .availability-card.hours{
    height: 50vh;
  }
}

  .availability-card-adjustment {
    height: 2.2em;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
  }

  .fc-scroller::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* Chrome/Safari/Webkit */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
  }

  .mt-20 {
    margin-top: 20px;
  }

  .custom-button {
    margin:0 !important;
  }
  .modal-footer {
    justify-content: center !important;
  }

  .ModalHeader{
    text-align: center;
   /*  margin-bottom: 15px; */
    
  }
  .TemplateModal{
    margin-top: 0px!important;
  
  }
  

  @media screen and (max-width: 768px) {
    .checkbox-arr {
      flex-direction: column;
    }
    .doc-times.doc-times-modal {
      padding: 0 16px;
    }
  }
  
  .close-button{
    display: flex;
    margin-top: -47px;
    margin-left: 96%;
  }

  .slot-conflict {
    border: 1px solid red;
    padding: 10px 5px;
    margin-bottom: 10px;
    color: black;
  }

  .slot-conflict b{
    color: red;
  }

  .time-slot-speciality-text {
    margin-bottom: 0;
    border-radius: 4px;
    background: white;
    color: black;
    padding: 3px 15px;
  }

  .time-slot-label {
    padding: 3px 0 3px 15px;
    width: 150px;
    display: inline-block;
  }

  .slot-conflict svg{
    margin-right: 5px;
  }

  .slot-conflict-text {
    display: inline;
  }

  .card-element {
    border: 1px solid #203A45;
    padding: 10px;
  }

  .btn-wrapper {
    text-align: center;
  }

  .btn-wrapper > button {
    width: 225px;
  }

  .hand-cursor {
    cursor: pointer;
  }

  .modal-close {
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: -3px;
    color: #38c485;
    z-index: 99;
    font-size: 24px;
  }

  .delete-btn-config-modal{

    background-color: #6c757d;
    border-color: #6c757d;

  }

  .delete-btn-config-modal:hover {
    background-color: #5a6268;
    border-color: #545b62;
  }
  .copy-btn-config-modal{

    background-color: #38C485;
    border-color: #38C485;

  }

  .copy-btn-config-modal:hover {
    background-color: #30a671;
    border-color: #2d9c6a;
  }

  .btn-width-fix{
    padding: -0.5em !important;
    line-height: 1;
    height: 2.1em;
    min-width: 70px;
    max-width: auto;
    font-size: 1em;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 10%);
  }