.prescription-info-container {
  margin-top: 2.5em;
  height: auto;
  overflow: hidden;
}

.prescription-info-back {
  margin: 1.25em;
  font-size: 1.25em;
}

.prescription-info-header {
  margin: .5em;
}

.prescription-info-header-container {
  margin: 1.5em 0;
}

.prescription-view-container {
  margin: 1em 1.13em;
}

.prescription-pdf-viewer {
  height: 43.5vh;
  text-align: center;
  background-color: #f2f2f2;
  margin: 1em 0;
  overflow-y: scroll;
}

.prescription-pdf-viewer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.prescription-button-width {
  width: 12em;
  margin: 0 5px;
}

.prescription-action-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.prescription-status-badge {
  font-size: 1em;
  width: 145px;
  margin-right: 1em;
}

.prescription-info-buttons-container {
  padding: 0 .5em;
}

.bold-text {
  margin-top: .5em;
}

.timeline-view-container {
  height: 57vh;
  margin: 1em 1.13em;
  overflow: auto;
}

.timeline-card {
  margin-top: .75em;
}

.card-title {
  color: #143A45 !important;
}

.card-sub-title {
  font-weight: 500 !important;
  color: grey !important;
}

.show-more {
  display: none !important;
}

.css-q6vr8b-TimelineContentDetails {
  font-weight: 500 !important;
  color: grey !important;
}

.card-description {
  margin: 2px !important;
}

div.card-description {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.chrono-custom-content p {
  padding-left: 20px;
  margin-bottom: 0;
  color: grey;
}

.chrono-custom-content p b {
  color: grey;
  font-weight: 600;
}

.email-exclamation {
  margin: 0 .5em;
  margin-bottom: 0.5em;
  color: #00a1fa;
}

.exclamation-wrapper {
  display: inline-flex;
}

@media screen and (min-height: 600px) and (max-height: 700px) {
  .prescription-info-container {
    margin-top: 2.5em;
    height: 75vh;
    overflow: hidden;
  }
  .prescription-pdf-viewer {
    height: 22vh;
    text-align: center;
    background-color: #f2f2f2;
    margin: 1em 0;
    overflow-y: scroll;
  }
  .prescription-status-badge {
    margin-top: 7px;
    margin-right: .5em;
    font-size: 1em;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .timeline-view-container {
    height: 42vh;
    margin: 1em 1.13em;
    overflow: auto;
  }
}

@media screen and (min-height: 701px) and (max-height: 800px) {
  .prescription-info-container {
    margin-top: 2.5em;
    height: 75vh;
    overflow: hidden;
  }
  .prescription-pdf-viewer {
    height: 31vh;
    text-align: center;
    background-color: #f2f2f2;
    margin: 1em 0;
    overflow-y: scroll;
  }
  .prescription-status-badge {
    margin-top: 7px;
    margin-right: .5em;
    font-size: 1em;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .timeline-view-container {
    height: 46.8vh;
    margin: 1em 1.13em;
    overflow: auto;
  }
}

@media screen and (min-height: 801px) and (max-height: 900px) {
  .prescription-info-container {
    margin-top: 2.5em;
    height: 75vh;
    overflow: hidden;
  }
  .prescription-pdf-viewer {
    height: 35vh;
    text-align: center;
    background-color: #f2f2f2;
    margin: 1em 0;
    overflow-y: scroll;
  }
  .prescription-status-badge {
    margin-top: 7px;
    margin-right: .5em;
    font-size: 1em;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .timeline-view-container {
    height: 49.7vh;
    margin: 1em 1.13em;
    overflow: auto;
  }
}
