.margin-1em {
    margin: 30px -20px 10px -20px;
}

.sidebar-brand {
    background-color: white;
}

.sidebar-brand:hover {
    background-color: white;
}

.profile-header {
    padding-bottom: 0px;
}

.pd-halfrem.profile-card {
    padding-top: 15px;
    height: 78.5vh;
    overflow-y: auto;
    border-bottom: solid 1em white;
}

.text-area {
    min-height: 100px;
}

.banner {
    width: 100%;
    font-weight: bold;
}

.nav-bar-profile-pic {
    position: absolute;
    right: 3.7em;
    top: 0.5em;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    background-color: #00c789;
    color: white;
    border-style: solid;
    border-radius: 5px;
    border-color: #00c789;
    display: inline-block;
    padding: 6px 10px;
    cursor: pointer;
}

.pd-halfrem {
    padding-top: 0.5rem;
}

/* .ql-editor {
    height: 80px;
    min-height: 57px !important;
    max-height: 80px;
    overflow-y: auto;
    overflow-x: auto;
} */

.custom-file-upload:hover {
    background-color: #00a874;
    transition: 500ms ease;
    border-style: solid;
    border-radius: 5px;
    border-color: #00a874;
}

.custom-file-upload:active {
    border-style: solid;
    border-radius: 5px;
    border-color: #8fe7cc;
}

.home_contact_details {
    border: 1px solid #eaeaea;
    background: #f9f9f9;
    padding: 10px 20px;
    border-radius: 5px;
}

.office_contact_details {
    border: 1px solid #eaeaea;
    background: #f9f9f9;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.professional_practice_details {
    border: 1px solid #eaeaea;
    background: #f9f9f9;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.general_personal_details {
    border: 1px solid #eaeaea;
    background: #f9f9f9;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.professional_practice_details h5,
.office_contact_details h5,
.home_contact_details h5 {
    margin-bottom: 15px;
    display: inline-block;
}

.office_contact_details svg,
.home_contact_details svg {
    display: inline-block;
    float: right;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
}

.MuiInput-underline-38:after {
    border-bottom: 2px solid #38c485 !important;
}

.general_personal_details .ql-editor {
    height: 150px;
    min-height: 125px !important;
    overflow-x: visible;
}

/* .general_personal_details .ql-editor ::-webkit-scrollbar {
    display: none;
} */
.MuiInput-underline-20:after {
    border-bottom: 2px solid #38c485;
}

.MuiInput-underline-20:before {
    border-bottom: 2px solid #38c485;
}

.react-select-container .react-select__value-container {
    height: auto !important;
}

.profile-pic-upload {
    display: flex;
    align-items: center;
}

.profile-pic-upload .position {
    position: absolute;
    right: 2.5em;
}

.sign-placeholder {
    height: 135px;
}

.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 6;
    display: table;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(0, 199, 137, 0.05);
    border-top-color: #00c789;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.language-sub-text {
    color: #a9a9a9;
    font-size: 11px;
    margin-top: 2px;
}

.profile-adjustment {
    margin: auto;
}

.signature-adjustment {
    width: 85%;
}

.profile-pic-upload.profile-adjustment img,
.profile-pic-upload.signature-adjustment img {
    max-width: 100%;
}

.profile-pic-upload.profile-adjustment .image-holder,
.profile-pic-upload.signature-adjustment .image-holder {
    position: relative;
}


.profile-pic-upload.profile-adjustment .image-holder label {
    position: absolute;
    right: -10px;
    bottom: 0px;
    border-radius: 50%;
}

.profile-pic-upload.signature-adjustment .image-holder label {
    position: absolute;
    right: -10px;
    bottom: -10px;
    margin-bottom: 0px;
    border-radius: 50%;
}

.image-holder label:hover {
    border-radius: 50%;
}

.general_personal_details .ql-editor {
    height: 250px;
    overflow-x: visible;
}

.professional-documents button {
    float: right;
    display: inline-block;
}

p.image-uploader-text {
    font-size: 10px;
    margin-top: 10px;
    line-height: 12px;
}

.widthheight128 {
    width: 128px;
    height: 128px;
    position: relative;
    margin: 0 auto;
}

.widthheight256128 {
    /* width: 210px;
    height: 105px; */
    position: relative;
    margin: 0 auto;
}

@media(max-width:767px) {
    .pd-halfrem.profile-card {
        padding-bottom: 300px;
    }

    .pd-halfrem.profile-card button {
        margin-top: 15px;
    }

    .mobile-fullwidth.col {
        width: 100%;
        margin: 10px 0px;
        flex-basis: unset;
    }

    .pd-halfrem.profile-card .react-bootstrap-table {
        max-width: 100%;
        overflow: scroll;
    }

    .professional-documents button {
        float: unset;
        display: block;
    }

    .Country-of-registrations {
        max-width: 100%;
        overflow: scroll;
    }

    .pd-halfrem.profile-card td button:nth-last-child(1) {
        margin-left: 0px;
    }
}