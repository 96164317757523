
.appt-trend-card{
    border-radius: 0.2rem;
    box-shadow: 0 2px 4px rgb(126 142 177 / 12%);
    height: 70vh;
}
.appt-trend-graph{
    height: 360px;
}

.care-provider-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}