.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999 !important;
    display: table;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(0, 199, 137, 0.05);
    border-top-color: #00c789;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
    z-index: 9999;
  }

.overlay-z-index{
    z-index: 99999 !important
  }

.payment-config-header{
  color: #143A45
}
  .appointment-list-table div table tbody tr td {
    cursor: pointer;
  }

  .appointment-list-table div table tbody tr td .row-expansion-style {
    cursor: default;
  }
.payment-config-tab{
  color: #143A45;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.settings-tabs .nav-tabs .nav-link {

  background-color: #9daea7;
  border-radius: 5px;
}

.settings-tabs .nav-tabs .nav-link.active {
  background-color: #ffffff;
  border-radius: 5px;
}

.settings-tabs .nav-tabs .nav-link {
  cursor: pointer;
}

.opacity-zero {
  opacity: 0;
}

.FA-label{
  max-width: 57px;
  white-space: normal;
  
}

.fa-btn{
  height: 3em;
  min-width: 70px;
  font-size: 1em;
  padding: 0.5em;
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
}