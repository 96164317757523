.status-button-width {
    min-width: 70px;
    font-size: 1em;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
}

.current-email-height {
    height: 80px;
}

.email-edit-icon {
    margin-left: 1em;
    cursor: pointer;
}

.email-edit-icon:active {
    color: #00c789;
}

.account-button-div {
    display:flex;
    justify-content: center;

}

.account-email-change-button {
    min-width: 50px;
}

.account-email-change-banner {
    background-color: #f1f1f1;
    border-radius: 0.25rem;
    padding: 1em;
    margin: 1em;
}

.cancel-button {
    margin-left: 1em;
}

.account-settings-collapse {
    cursor: pointer;
}
/* .form-check-inline .form-check-input:before{
    background:#00c789;
    color: #00c789;
    border: 1px solid #00c789;
    }

    .form-check-inline .form-check-input:after{
        background:#00c789;
        color: #00c789;
        border: 1px solid #00c789;
        } */
.custom-checkbox{
    display: inline-block;
    margin-right: 15px;
}

.accounts-card {
    /* margin-top: -2%; */
    height: 85vh;
    overflow-y: auto;
}

.user-profile-card{
    
}

.patients-outside-checkbox {
    margin-left: -1em;
}

.margin {
    margin: 0.5rem 0 1rem 0;
}

.cursor-pointer {
    cursor: pointer;
}

.country-of-registration {
    margin: 0.5em 1em 1em 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #00875e;
    background-color: #00875e;
}

.custom-control-input:disabled~.custom-control-label::before {
    color: #fff !important;
    border-color: #00875e !important;
    background-color: #a0b9b2 !important;
}

.display-none {
    display: none;
}

.currency-exclamation {
    margin: 0 .5em;
    margin-bottom: 0.5em;
    color: #00a1fa;
    cursor: pointer;
}

.info-icon {
    display: flex;
    margin-left: 188px;
    margin-top: -23%;
    padding-bottom: 5%;
    font-size: larger;
}

.currency-info {
    text-align: left;
}

.flag-icon {
    display: inline;
    width: 6%;
    margin-right: 1em;
}

.multi-speciality-rows {
    margin-bottom: -1em;
}