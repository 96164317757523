  .schedule-calender {
    width: 97%;
    margin: auto;
    padding-top: 1.5em;
  }

  .cancel-slot-button-container {
    width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
  }

  .cancel-slot-button {
    position: absolute;
    right: 0;
    z-index: 10;
  }

  .speciality-dropdown{
    z-index: 9;
    width: 23%;
    position: relative;
    right: -68%;
    margin-bottom: -2.8%;

}

@media screen and (min-width: 768px) {
  .speciality-dropdown{
    z-index: 9;
    width: 22%;
    position: relative;
    margin-bottom: -2.3em;
    padding-right: 0.8em;
  }
}
/* @media screen and (min-width:  992px) {
  .speciality-dropdown{
    width: 25%;
    position: relative;
    margin-bottom: -2.35em;
    padding-right: 0.8em;
  }
} */

.container{

}

  .cancel-button {
    background-color: #4dc585 !important;
    border: 1px solid #4dc585 !important;
    padding: 0 0.5em;
  }

  .cancel-button:hover {
    background-color: #0a4f21;
    border: 1px solid #0a4f21;
  }

  .fc-icon {
    display: flex;
    width: 1em;
    height: 12em;
    font-size: 1.2em !important;
  }

  .fc-thisWeek-button {
    margin: 0 .4em !important;
    border-radius: 3px !important;
    background-color: #00865c !important;
    border-color: #00865c !important;
  }

  .fc-prevWeek-button {
    background-color: #00865c !important;
    border-color: #00865c !important;
  }

  .fc-nextWeek-button {
    background-color: #00865c !important;
    border-color: #00865c !important;
  }

  .schedule-card-adjustment {
    height: 3.5em;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    border-radius: 5px;
  }

  .schedule-legend {
    float: right;
    display: flex;
    flex-wrap: wrap;
  }
  
  .schedule-legend span {
    margin: 0 .5em 0 .5em;
    height: 1.25em;
    width: 1.25em;
  }

  .legend-available {
    background-color: #1b3b44;
    padding: 0.25em;
    color: white;
    border-radius: 5px;
    margin: 0 0.1em;
    font-size: 0.9em;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .legend-unavailable {
    background-color: #7f8389;
    padding: 0.25em;
    color: white;
    border-radius: 5px;
    margin: 0 0.1em;
    font-size: 0.9em;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .legend-scheduled {
    background-color: #38C485;
    padding: 0.25em;
    color: white;
    border-radius: 5px;
    margin: 0 0.1em;
    font-size: 0.9em;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .legend-in-progress {
    background-color:#EFB918;
    padding: 0.25em;
    border-radius: 5px;
    margin: 0 0.1em;
    font-size: 0.9em;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .legend-completed {
    background-color: #6499D2;
    padding: 0.25em;
    color: white;
    border-radius: 5px;
    margin: 0 0.1em;
    font-size: 0.9em;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .legend-cancelled {
    background-color:#FF5A5A;
    padding: 0.25em;
    color: white;
    border-radius: 5px;
    margin: 0 0 0 0.1em;
    font-size: 0.9em;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
  }

  .schedule-calendar-logo {
    margin-top: .25em;
    margin-left: -1em;
    font-size: 1.2em;
  }

  .appointment-found-modal-height {
    min-height: auto;
    max-height: 25vh;
    overflow-y: scroll;
  }

  .disclaimer-card-container {
    display: flex;
    border-radius: 50px;
    background-color: #f7e67d !important;
  }

  .unavailable-slots:hover {
    cursor: pointer;
    background-color: #b1b1b1 !important;
    transition: 100ms ease;
  }

  .date-label-spacer {
    margin-bottom: .5em;
  }

  .test-class {
    margin-left: 6.6em;
  }

  .availability-disclaimer {
    display: flex;
    color: #ab000d;
    border-radius: 5px;
    padding: 0.7em 1em 0 1em;
    background-color: #f8f8f8;
    margin-bottom: 1em;
}

.availability-warning-text {
  margin-left: 0.7em;
  color: grey;
}

.slot-chips {
  background-color: #a9e2ee;
  width: fit-content;
  margin: 0.7em;
  padding: 0.7em;
  border-radius: 2em;
  -webkit-box-shadow: -1px 0px 5px 2px rgba(0,0,0,0.10);
  -moz-box-shadow: -1px 0px 5px 2px rgba(0,0,0,0.10);
  box-shadow: -1px 0px 5px 2px rgba(0,0,0,0.10);
}

.appointment-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 0.5rem, 1rem;
}

.speciality-select{
  margin: 0 auto;
  text-align: left;
  width: 50%;
  padding-bottom: 14px;
}

.cancel-slot-footer{
  margin: 0 auto;
  text-align: left;
}